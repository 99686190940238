<template>
  <div>
    <UserTopNav />
    <router-view></router-view>
  </div>
</template>

<script>
import UserTopNav from "../../../components/UserTopNav.vue";

export default {
  components: { UserTopNav },
};
</script>
